<template>
	<v-layout>
		<!-- Categories -->
		<v-expansion-panel v-model="openedPanel">
			<v-expansion-panel-content>
				<template v-slot:header>
					<v-layout column>
						<v-flex v-t="'offers.services'" title />
					</v-layout>
				</template>
				<v-card>
					<v-card-text class="pa-0">
						<ListOfCategories :mini="mini" :loading="loading" :readonly="isReadonly" />
					</v-card-text>
				</v-card>
			</v-expansion-panel-content>
			<v-expansion-panel-content>
				<template v-slot:header>
					<v-layout column>
						<v-flex v-t="'offers.offers'" title />
					</v-layout>
				</template>
				<v-card>
					<v-card-text class="pa-0">
						<v-list>
							<v-list-tile avatar>
								<v-list-tile-content>
									<v-text-field
										v-model="search"
										clearable
										:label="$t('actions.search')"
										prepend-icon="search"
										@blur="onSearchTextChanged"
										@click:clear="onSearchTextChanged"
										@keyup.enter="onSearchTextChanged"
									/>
								</v-list-tile-content>
							</v-list-tile>
							<v-subheader v-t="'offers.filter.categories'" />
							<v-list-tile v-for="category in categories" :key="category.id">
								<v-list-tile-avatar>
									<!-- Category color -->
									<v-tooltip v-if="mini" top>
										<template v-slot:activator="{ on }">
											<v-icon :color="category.color" large v-on="on">brightness_1</v-icon>
											<span
												style="
													position: absolute;
													top: 50%;
													left: 50%;
													-moz-transform: translate(-50%, -50%);
													transform: translate(-50%, -50%);
													color: white;
													font-size: 0.8em;
													font-weight: bold;
												"
												v-on="on"
												v-text="category.abbreviation"
											/>
										</template>
										<span v-text="category.name" />
									</v-tooltip>
									<v-icon v-if="!mini" :color="category.color" large>brightness_1</v-icon>
									<span
										v-if="!mini"
										style="
											position: absolute;
											top: 50%;
											left: 50%;
											-moz-transform: translate(-50%, -50%);
											transform: translate(-50%, -50%);
											color: white;
											font-size: 0.8em;
											font-weight: bold;
										"
										v-text="category.abbreviation"
									/>
								</v-list-tile-avatar>
								<!-- Category name -->
								<v-list-tile-content v-if="!mini" class="text-truncate" v-text="category.name"></v-list-tile-content>
								<v-list-tile-action>
									<v-checkbox v-model="category.search" color="primary" @change="onCheckboxChanged" />
								</v-list-tile-action>
							</v-list-tile>
						</v-list>
					</v-card-text>
				</v-card>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

import OffersModuleGuard from '@/mixins/ModulesGuards/Offers/OffersModuleGuard'

export default {
	name: 'OffersNavDrawer',
	components: {
		ListOfCategories: () => ({
			component: import('@/components/Offers/ListOfCategories')
		})
	},
	mixins: [OffersModuleGuard],
	data: function () {
		return {
			lastSearch: {},
			loading: false,
			mini: false,
			search: ''
		}
	},
	computed: {
		...mapState({
			categories: state => state.offers.categories
		}),
		categoriesSelected: function () {
			let result = []
			result = this.categories
				.filter(item => {
					return item.search
				})
				.map(item => item.id)
			return result
		},
		isReadonly: function () {
			return this.service.getIsReadonly()
		},
		openedPanel: {
			get: function () {
				const routeName = this.$route.name
				if (routeName.includes('offers-category')) {
					return 0
				} else if (routeName == 'offers-catalog-offers') {
					return 1
				}
				return undefined
			},
			set: function (val) {
				let newRouteName = null
				if (val == 0) {
					newRouteName = 'offers-category'
				} else if (val == 1) {
					newRouteName = 'offers-catalog-offers'
				}
				if (newRouteName && !this.$route.name.includes(newRouteName)) {
					this.appService.goTo(newRouteName)
				}
			}
		}
	},
	created: function () {
		this.appEventBus.emit(this.appEvents.MAXIMIZE_SIDEBAR)
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.SIDEBAR_MINIMIZED, action: this.onSidebarMinimized },
				{ event: this.appEvents.SIDEBAR_MAXIMIZED, action: this.onSidebarMaximized }
			]
		},
		onSidebarMaximized: function () {
			this.mini = false
		},
		onSidebarMinimized: function () {
			this.mini = true
		},
		launchSearch: function (params) {
			this.lastSearch = params
			this.eventBus.emit(this.events.GET_OFFERS, params)
		},
		onCheckboxChanged: function () {
			this.lastSearch.categories = this.categoriesSelected
			this.launchSearch(this.lastSearch)
		},
		onSearchTextChanged: function () {
			if (typeof this.lastSearch.text !== 'string' && this.search.length > 0) {
				this.lastSearch.text = this.search
				this.launchSearch(this.lastSearch)
			}
			if (typeof this.lastSearch.text === 'string' && this.lastSearch.text.trim() !== this.search.trim()) {
				this.lastSearch.text = this.search
				this.launchSearch(this.lastSearch)
			}
		}
	}
}
</script>
