<template>
	<router-view v-if="!loading"></router-view>
</template>

<script>
import Vue from 'vue'

import OffersModuleGuard from '@/mixins/ModulesGuards/Offers/OffersModuleGuard'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";
import OffersNavDrawer from '@/components/Offers/OffersNavDrawer'

export default {
	name: 'OffersModule',
	mixins: [
		OffersModuleGuard,
		HandleSideBarMixin,
	],
	data: function () {
		return {
			loading: false
		}
	},
	watch: {
		accountingFirmId: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.loading = true
					this.service.onAccountingFirmChanged(newVal).finally(() => {
						this.loading = false
					})
				}
			},
			immediate: true
		}
	},
	created: function () {
		const OffersNavDrawerClass = Vue.extend(OffersNavDrawer)
		const OffersNavDrawerComponent = new OffersNavDrawerClass({
			parent: this
		})
		this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
			canMinimize: false,
			moduleInstance: OffersNavDrawerComponent,
			emitter: this
		})
		this.setHasSidebar(true)
		this.setPermanentSidebar(true)
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
		this.setHasSidebar(false)
		this.setPermanentSidebar(false)
	},
}
</script>
